<template>
  <div class="modal-dialog modal-lg" >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >
          คำสั่งซื้อ :{{ confirm_key }}
        </h5>
        <button
          class="btn-close"
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div
            class="col-xl-4 col-sm-4 upload-item"
            v-for="(file, index) in images_order"
            :key="index"
          >
            <p style=" word-break: break-all;white-space: normal;" >IS{{file.photographer_id }}, {{ file.original_file_name }}</p>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "orderDetail",
  props: { images_order: Object, order_id: String, confirm_key: String },
  setup(props) {
    ///console.log(props.order_id);
  },
  data() {
    return {
      orderId: null,
      result: "null",
    };
  },
};
</script>

<style coped lang="scss">

.upload-item {
  border: 1px solid rgb(197, 197, 197);
  border-radius: 0.5rem;
  display: grid;
  float: left;
  position: relative; 
  //padding: 0.5rem;
  //font-size: smaller;
  padding: 0px;
  text-align: center;
} 

</style>