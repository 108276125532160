<template>
  <li class="onhover-dropdown">
    <div class="notification-box">
      <svg>
        <use href="@/assets/svg/icon-sprite.svg#notification" @click="notification_open()"></use>
      </svg>

      <span class="badge rounded-pill badge-secondary">1</span>
    </div>
    <div
      class="onhover-show-div notification-dropdown"
      :class="{ active: notification }"
    >
      <h6 class="f-18 mb-0 dropdown-title">ข่าวสาร</h6>
      <ul>
        <li class="b-l-success border-4">
          <p>ติดปัญหาการใช้งานแจ้งได้ที่ @ishootrun.in.th</p>
        </li>
        
        
      </ul>
    </div>
  </li>
</template>

<script>

  export default {
    name: 'Notifications',
    data() {
      return {
        notification: false,
      };
    },
    methods: {
      notification_open() {
        this.notification = !this.notification;
      },
    },
  };
</script>
