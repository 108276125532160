<template>
  <div class="row">&nbsp;</div>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="row">
          <WelcomeCard />
        </div>
      </div>

      <div class="col-xl-12">
        <div class="card" v-if="!event_partner">loading...</div>
        <div class="card" v-if="event_partner">
          <div class="card-header card-no-border">
            <div class="header-top">
              <h5 class="m-0">กิจกรรมที่เปิดกับเรา</h5>
            </div>
          </div>
          <div class="card-body pt-0 campaign-table">
            <div class="table-responsive currency-table">
              <table class="table">
                <thead>
                  <tr>
                    <th class="f-light">กิจกรรม</th>
                    <th class="f-light" style="text-align: center">จัดการ</th>
                    <th class="f-light" style="text-align: center">รายงานขาย</th>
                    <th class="f-light" style="text-align: center">ส่งการบ้าน</th>
                    <th class="f-light" style="text-align: center">ไลน์กลุ่ม</th>
                    

                    
                  </tr>
                </thead>
                <tbody
                  v-if="this.is_loading === 'N' && event_partner.length === 0"
                >
                  <tr>
                    <td colspan="5">
                      <div class="d-flex justify-content-center" >
                        ยังไม่มีกิจกรรมที่เปิดกับเรา 
                      </div>
                      <div class="d-flex justify-content-center" >
                        คุณสามารถติดต่องาน และเปิดใช้ระบบค้นหากับเราได้ทุกเมื่อ...
                        
                      </div>
                      <div class="d-flex justify-content-center" >
                        หรือจะทดลองใช้ระบบก่อนเราก็ยินดี &nbsp;<i class="fa fa-heart" style="font-size:10px;color:red"></i>
                        
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="this.is_loading === 'Y' && event_partner.length === 0"
                >
                  <tr>
                    <td colspan="5">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="event_partner.length !== 0">
                  <tr v-for="event in event_partner" :key="event">
                    <td >
                      [{{ event.event_date_text }}]&nbsp;{{ event.title }}
                    </td>
                    <td style="text-align: center">
                      <a v-if="user.role_name === 'system'||user.role_name === 'systemadmin'" :href="'/event-adjustment-admin/' + event.event_key">กิจกรรม/ช่างภาพ</a>
                      <a v-else :href="'/event-adjustment/' + event.event_key">กิจกรรม/ช่างภาพ</a>
                    </td>
                    <td style="text-align: center"><a :href="'/order-admin/' + event.event_key">รายงาน</a></td>
                    <td style="text-align: center">
                      <div v-if="event.google_drive === null || event.google_drive === ''"> รอเพิ่มข้อมูล</div>
                      <div v-else ><a target="_blank" :href="event.google_drive" >Share drive</a></div>
                    </td>
                    <td style="text-align: center">
                      <div v-if="event.line_group === null || event.line_group === ''"> รอเพิ่มข้อมูล</div>
                      <div v-else ><a target="_blank" :href="event.line_group" >Line group</a></div>
                    </td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.disabled {
  opacity: 0;
  pointer-events: none;
}
</style>

<script>
import axios from "axios";
export default {
  data() {
    return {
      event_partner: [],
      is_loading: "N",
      loading: true,
      user: {},
    };
  },
  async mounted() {
    this.is_loading = "Y";
    try {
      this.user = JSON.parse(localStorage.getItem("user"));
      const response = await axios.post("/v1/partner/event-partner", {
        withCredentials: true,
      });
      if (response.data.status === "success") {
        this.event_partner = response.data.body;
        this.is_loading = "N";
        this.loading = false;
      } else {
        //this.$router.push({ path: "/auth/login" });
      }
    } catch (error) {
      //this.$router.push({ path: "/auth/login" });
    }
  },
};
</script>
