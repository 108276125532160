<template>
  <div class="col-xl-12">
    <div class="card" v-if="!event_mapping">loading...</div>
    <div class="card" v-if="event_mapping">
      <div class="card-header card-no-border">
        <div class="header-top">
          <h5 class="m-0">กิจกรรมที่เข้าร่วม</h5>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <!-- <div class="btn-group" role="group" >
          <button type="button" class="btn btn-outline-info" @click="events('ALL')" active >กิจกรรมทั้งหมด</button>
          <button type="button" class="btn btn-outline-info" @click="events('OPEN')">กิจกรรมโอเพ่น</button>
          <button type="button" class="btn btn-outline-info" @click="events('MYEVENT')">กิจกรรมของฉัน</button>
        </div> -->
        <div
          class="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio3"
            autocomplete="off"
            checked
          />
          <label
            class="btn btn-outline-primary"
            @click="events('MYEVENT')"
            for="btnradio3"
            >กิจกรรมของฉัน</label
          >

          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio2"
            autocomplete="off"
          />
          <label
            class="btn btn-outline-primary"
            @click="events('OPEN')"
            for="btnradio2"
            >กิจกรรมโอเพ่น</label
          >

          <input
            v-if="
              this.user.role_name === 'system' ||
              this.user.role_name === 'systemadmin'
            "
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio1"
            autocomplete="off"
          />
          <label
            v-if="
              this.user.role_name === 'system' ||
              this.user.role_name === 'systemadmin'
            "
            class="btn btn-outline-primary"
            @click="events('ALL')"
            for="btnradio1"
            >กิจกรรมทั้งหมด</label
          >
        </div>
      </div>

      <div class="card-body pt-0 campaign-table">
        <p>&nbsp;</p>
        <div class="d-flex justify-content-left" v-if="this.is_event_type_open === 'Y'" >
          <p>
            <i class="fa fa-info-circle"></i> &nbsp;กิจกรรม open: คืองานที่ช่างภาพ
            สามารถขออนุญาติ และลงทะเบียนเข้าร่วมถ่ายได้เลย
            แต่ช่างภาพต้องเข้าร่วมกลุ่ม Line ก่อนวันจัดกิจกรรม<br>&nbsp;&nbsp;&nbsp;&nbsp;
            เพื่อรับทราบข้อมูลกิจกรรมและข้อตกลงของการเข้าถ่ายนั้นๆ
            โดย อาจจะไม่ได้สิทธิในการเข้าถ่าย ถ้าไม่เข้ากลุ่ม Line (บางกิจกรรม)
            <!-- <br>
            <i class="fa fa-info-circle"></i>&nbsp;กิจกรรม open: ถ้ามีการแจกภาพ หรือจำหน่ายภาพผ่านช่องทางอื่นๆ ขอความร่วมมือ ทำหลังจากเปิดขายรูปผ่านระบบเกิน 24 ชม. -->
          </p>
          
          <p>&nbsp;</p>
          
        </div>
        
        <!-- event open -->
        <div
          class="table-responsive currency-table"
          v-if="this.is_event_type_open === 'Y'"
        >
          <table class="table">
            <thead>
              <tr>
                <th>กิจกรรม</th>
                <th style="text-align: center">ลงทะเบียนเข้าถ่าย</th>
                <th style="text-align: center">จำนวนช่างภาพ</th>
                <th style="text-align: center">ไลน์กลุ่ม</th>
              </tr>
            </thead>
            <tbody v-if="this.is_loading === 'N' && event_mapping.length === 0">
              <tr>
                <td colspan="6">
                  <div class="d-flex justify-content-center">ไม่พบข้อมูล</div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="this.is_loading === 'Y' && event_mapping.length === 0">
              <tr>
                <td colspan="6">
                  <div class="d-flex justify-content-center">
                    <img
                      style="align-items: center; width: 60px"
                      src="../../assets/images/running_loading.svg"
                    />
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="event_mapping.length !== 0">
              <tr v-for="event in event_mapping" :key="event">
                <td>
                  {{ event.title }}&nbsp;
                  [<vue-feather type="user-check"></vue-feather>Open] <p style=" font-size: 9px;">วันที่ {{ event.event_date_text }}&nbsp; ณ. {{ event.province }}</p>
                </td>

                <td style="text-align: center">
                  <button
                    v-if="
                      event.user_register_count < event.number_of_photographer
                    "
                    @click="events_register(event.event_key)"
                    class="btn btn-primary" style="padding: 5px;"
                  >
                    ลงทะเบียน
                  </button>
                  <button
                    v-if="
                      event.user_register_count >= event.number_of_photographer
                    "
                    disabled
                    class="btn btn-light"
                  >
                    ช่างภาพเต็ม
                  </button>
                </td>
                <td style="text-align: center">
                  ({{ event.user_register_count }}/{{
                    event.number_of_photographer
                  }})
                </td>
                <td style="text-align: center">
                  <p style="font-size: 10px">-แสดงเมื่อลงทะเบียนสำเร็จ-</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="table-responsive currency-table"
          v-if="this.is_event_type_open !== 'Y'"
        >
          <table class="table">
            <thead>
              <tr>
                <th>กิจกรรมที่ลงทะเบียน</th>
                <th style="text-align: center">อัปโหลด</th>
                <th style="text-align: center">ออเดอร์</th>
                <th style="text-align: center">Share drive</th>
                <th style="text-align: center">ไลน์กลุ่ม</th>
                <th style="text-align: center">ยกเลิกลงทะเบียน</th>
              </tr>
            </thead>
            <tbody v-if="this.is_loading === 'N' && event_mapping.length === 0">
              <tr>
                <td colspan="6">
                  <div class="d-flex justify-content-center">ไม่พบข้อมูล</div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="this.is_loading === 'Y' && event_mapping.length === 0">
              <tr>
                <td colspan="5">
                  <div class="d-flex justify-content-center">
                    <img
                      style="align-items: center; width: 60px"
                      src="../../assets/images/running_loading.svg"
                    />
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="event_mapping.length !== 0">
              <tr v-for="event in event_mapping" :key="event">
                <td v-if="event.is_lock_user !== 'N'">
                  {{ event.title }}
                  <p style=" font-size: 9px;">วันที่ {{ event.event_date_text }}</p>
                </td>
                <td v-if="event.is_lock_user === 'N'">
                  {{ event.title }}&nbsp;
                  [<vue-feather type="user-check"></vue-feather>Open]
                  <p style=" font-size: 9px;">วันที่ {{ event.event_date_text }}</p>
                </td>

                <td style="text-align: center">
                  <router-link
                    v-if="event.uploadable === 'Y'"
                    :to="'/upload/' + event.event_key"
                    class="btn btn-primary" style="padding: 7px;"
                    >upload</router-link
                  >

                  <button
                    v-if="event.uploadable !== 'Y'"
                    class="btn btn-light"
                    disabled style="padding: 5px;"
                  >
                    ปิดการอัปโหลด
                  </button>
                </td>
                <td style="text-align: center">
                  <router-link
                    v-if="event.is_mhao === 'Y' || event.is_free_download_keep_contract ==='Y' || event.is_mhao_hybrid ==='Y'"
                    :to="'/orders/' + event.event_key"
                    class="btn btn-primary" style="padding: 7px;"
                    >order</router-link
                  >
                  <router-link
                    v-if="event.is_mhao !== 'Y' && event.is_free_download_keep_contract !=='Y' && event.is_mhao_hybrid !=='Y'"
                    :to="'/order/' + event.event_key"
                    class="btn btn-primary" style="padding: 7px;"
                    >order</router-link
                  >
                </td>
                <td style="text-align: center">
                  <div
                    v-if="
                      event.google_drive === null || event.google_drive === ''
                    "
                  >
                  <p style="font-size: 9px;">รอเพิ่มข้อมูล</p>
                  </div>
                  <div v-else>
                    <a target="_blank" :href="event.google_drive"
                      ><p style="font-size: 9px;">Share drive</p></a
                    >
                  </div>
                </td>
                <td style="text-align: center">
                  <div
                    v-if="event.line_group === null || event.line_group === ''"
                  >
                  <p style="font-size: 9px;">รอเพิ่มข้อมูล</p>
                  </div>
                  <div v-else>
                    <a target="_blank" :href="event.line_group"><p style="font-size: 9px;">Line group</p></a>
                  </div>
                </td>

                <td style="text-align: center">
                  <button
                    disabled
                    v-if="event.uploadable === 'N'"
                    class="btn btn-danger" style="padding: 5px;"
                  >
                    ยกเลิก
                  </button>
                  <button
                    v-if="event.uploadable === 'Y'"
                    @click="events_unregister(event.event_key)"
                    class="btn btn-danger" style="padding: 5px;"
                  >
                    ยกเลิก
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.disabled {
  opacity: 0;
  pointer-events: none;
}
</style>
<script>
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/theme-bootstrap-4/bootstrap-4.scss";
import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      event_mapping: [],
      is_loading: "N",
      loading: true,
      user: {},
      event_load_type: null,
      is_event_type_open: "N",
    };
  },
  async mounted() {
    await this.events("MYEVENT");
  },
  methods: {
    logout: async function () {
      this.cookies.remove("jwt");
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      localStorage.removeItem("user_id");

      this.$router.replace("/auth/login");
    },
    async events(_event_load_type) {
      this.event_mapping= [];
      if (_event_load_type === "OPEN") {
        this.is_event_type_open = "Y";
      } else {
        this.is_event_type_open = "N";
      }
      this.is_loading = "Y";
      try {
        this.user = JSON.parse(localStorage.getItem("user"));

        let data = {
          event_load_type: _event_load_type,
        };
        //console.log("data:",data)
        const response = await axios.post("/v1/user/event", data, {
          withCredentials: true,
        });
        if (response.data.status === "success") {
          this.event_mapping = response.data.body;
          //console.log("this.event_mapping"+JSON.stringify(this.event_mapping))
          this.is_loading = "N";
          this.loading = false;
        } else {
          alert("เซสชั่นหมดอายุ กรุณาเข้าล็อกอิน เข้าสู่ระบบอีกครั้ง");
          this.logout();
          location.reload();
        }
      } catch (error) {
        //this.$router.push({ path: "/auth/login" });
      }
    },
    async events_register(_event_key) {
      //   let result = await Swal.fire({
      //   icon: "info",
      //   title:
      //     "<b style='color: red'><u>ข้อตกลงการเข้าร่วมกิจกรรมโอเพ่น</u></b>",
      //   html: "งานโอเพ่น คืองานที่ช่างภาพ สามารถเข้าร่วมถ่ายได้ แต่ช่างภาพต้องเข้าร่วมกลุ่ม Line ก่อนวันจัดกิจกรรม เพื่อรับทราบข้อมูลกิจกรรมและข้อตกลงของการเข้าถ่ายนั้นๆ <br> เพื่อให้จำนวนของช่างภาพที่เข้าถ่าย เป็นไปตามที่ตกลงกับผู้จัดกิจกรรม <br> คุณต้องการลงทะเบียนเข้าร่วมกิจกรรม ?",

      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#7991a8",
      //   confirmButtonText: "ลงทะเบียนเข้าร่วม",
      //   cancelButtonText: "ยกเลิก",
      // }).then((result) => {
      //   if (result.isConfirmed) {

      //   }else{

      //   }
      //    console.log("rs",result)

      // });

      if (
        confirm(
          "กิจกรรม open: คือกิกรรมที่ช่างภาพ สามารถเข้าร่วมถ่ายได้ แต่ช่างภาพต้องเข้าร่วมกลุ่ม Line ก่อนวันจัดกิจกรรม เพื่อรับทราบข้อมูลกิจกรรมและข้อตกลงของการเข้าถ่ายนั้นๆ เพื่อให้จำนวนของช่างภาพที่เข้าถ่าย เป็นไปตามที่ตกลงกับผู้จัดกิจกรรม  คุณต้องการลงทะเบียนเข้าร่วมกิจกรรม ?"
        )
      ) {
        try {
          let data = {
            event_key: _event_key,
          };
          //console.log("data:",data)
          const response = await axios.post("/v1/user/event-register", data, {
            withCredentials: true,
          });
          //console.log("response:"+JSON.stringify(response))
          if (response.data.status === "success") {
            if (response.data.message === "duplicate") {
              alert(
                "คุณได้ลงทะเบียนเข้าร่วม แล้ว กรุณาตรวจสอบที่ กิจกรรมของฉัน"
              );
              await this.events("MYEVENT");
            } else {
              alert("ลงทะเบียนเข้าร่วม สำเร็จ กรุณาตรวจสอบที่ กิจกรรมของฉัน");
              location.reload();
            }
          } else {
            alert("พบข้อผิดพลาด กรุณารีเฟรชหน้าใหม่ แล้วลองอีกครั้ง");
            location.reload();
          }
        } catch (error) {
          console.error("events_register: ", error);
        }
      }
    },
    async events_unregister(_event_key) {
      if (confirm("คุณต้องยกเลิก การลงทะเบียนเข้าร่วมกิจกรรม ?")) {
        try {
          let data = {
            event_key: _event_key,
          };
          //console.log("data:",data)
          const response = await axios.post("/v1/user/event-unregister", data, {
            withCredentials: true,
          });
          if (response.data.status === "success") {
            alert("สำเร็จ");
            await this.events("MYEVENT");
          } else {
            alert("พบข้อผิดพลาด กรุณารีเฟรชหน้าใหม่ แล้วลองอีกครั้ง");
            location.reload();
          }
        } catch (error) {
          console.error("events_register: ", error);
        }
      }
    },
  },
};
</script>
