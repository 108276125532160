<template>
    <div class="col-xl-4 col-sm-6 col-xxl-3 col-ed-4 box-col-4">
        <div class="card social-profile">
            <div class="card-body">
                <div class="social-img-wrap">
                    <div class="social-img"><img class="img-fluid" src="@/assets/images/avtar/11.jpg" alt="profile"></div>
                    <div class="edit-icon">
                        <svg>
                            <use href="@/assets/svg/icon-sprite.svg#profile-check"></use>
                        </svg>
                    </div>
                </div>
                <div class="social-details">
                    <h5 class="mb-1"><router-link to="/app/socialPage">Dev John</router-link></h5><span
                        class="f-light">@john.dev</span>
                    <ul class="card-social">
                        <li><a href="https://www.facebook.com/" target="_blank"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="https://accounts.google.com/" target="_blank"><i class="fa fa-google-plus"></i></a>
                        </li>
                        <li><a href="https://twitter.com/" target="_blank"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="https://rss.app/" target="_blank"><i class="fa fa-rss"></i></a></li>
                    </ul>
                    <ul class="social-follow">
                        <li>
                            <h5 class="mb-0">1,274</h5><span class="f-light">Posts</span>
                        </li>
                        <li>
                            <h5 class="mb-0">15.0k</h5><span class="f-light">Followers</span>
                        </li>
                        <li>
                            <h5 class="mb-0">1874</h5><span class="f-light">Following</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-sm-6 col-xxl-3 col-ed-4 box-col-4">
        <div class="card social-profile">
            <div class="card-body">
                <div class="social-img-wrap">
                    <div class="social-img"><img class="img-fluid" src="@/assets/images/avtar/16.jpg" alt="profile"></div>
                    <div class="edit-icon">
                        <svg>
                            <use href="@/assets/svg/icon-sprite.svg#profile-check"></use>
                        </svg>
                    </div>
                </div>
                <div class="social-details">
                    <h5 class="mb-1"><router-link to="/app/socialPage">Johan Deo</router-link></h5><span
                        class="f-light">@deo.johan</span>
                    <ul class="card-social">
                        <li><a href="https://www.facebook.com/" target="_blank"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="https://accounts.google.com/" target="_blank"><i class="fa fa-google-plus"></i></a>
                        </li>
                        <li><a href="https://twitter.com/" target="_blank"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="https://rss.app/" target="_blank"><i class="fa fa-rss"></i></a></li>
                    </ul>
                    <ul class="social-follow">
                        <li>
                            <h5 class="mb-0">500</h5><span class="f-light">Posts</span>
                        </li>
                        <li>
                            <h5 class="mb-0">8.0k</h5><span class="f-light">Followers</span>
                        </li>
                        <li>
                            <h5 class="mb-0">570</h5><span class="f-light">Following</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
</div></template>
