<template>
    <Breadcrumbs title="User Profile" main="Users" />


    <div class="container-fluid ">
        <div class="user-profile">
            <div class="row">
                <tourProfile />

            </div>
        </div>
    </div>
</template>

<script>
import tourProfile from "./tourProfile.vue";


export default {
    name: 'userProfile',
    components: {
        tourProfile,
    },

}
</script>
