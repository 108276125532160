<template>
  <Breadcrumbs title="กระเป๋าเงินช่างภาพ" main="Pages" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <form @submit.prevent="withdrawal">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <b>เลขบัญชีธนาคาร</b>
                </div>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="this.bank_account_no"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>ธนาคาร</b>
                </div>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="this.bank_name"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>ชื่อบัญชี</b>
                </div>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="this.bank_account_name"
                    disabled
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <b>ยอดเงินคงเหลือ</b>
                </div>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="this.wallet_amount"
                    disabled
                  />
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-md-6">
                  <b>ค่าธรรมเนียมการโอนเงิน</b>
                </div>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="this.wallet_free"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>ยอดเงินคงเหลือ</b>
                </div>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="this.wallet_amount"
                    disabled 
                  />
                </div>
              </div> -->
              <div class="row">&nbsp;</div>
              <div
                class="d-flex justify-content-center"
                v-if="this.is_loading === 'Y'"
              >
                <vue-feather
                  type="loader"
                  size="20"
                  animation="spin"
                ></vue-feather>
                กำลังโหลดข้อมูล...
              </div>

              <p>&nbsp;</p>

              <div class="row">
                <b>หมายเหตุ</b><br />
                * ถอนขั้นต่ำ 200 บาท<br />
                * ค่าธรรมเนียมการถอนครั้งละ 30 บาท<br />
                * โปรดตรวจสอบข้อมูลให้ถูกต้อง เงินจะเข้าบัญชีของท่าน ภายใน 3
                วันทำการ
              </div>
              <p>&nbsp;</p>
              <div class="row">
                <div class="d-flex justify-content-center">
                  <button
                    type="submit"
                    style="cursor: pointer"
                    class="btn btn-primary btn-lg"
                  >
                    ถอนเงิน
                  </button>
                  &nbsp;
                  <button
                    style="cursor: pointer"
                    @click.prevent="clear_result"
                    type="reset"
                    class="btn btn-secondary btn-lg"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-3"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      bank_account_name: "",
      bank_account_no: "",
      bank_name: "",
      wallet_amount: 0.0,
      wallet_free: 30,
      is_loading: "",
    };
  },
  async mounted() {
    try {
      this.is_loading = "Y";
      
      const responseUser = await axios.get("/v1/user/user", {
        withCredentials: true,
      });

      //console.log("responseUser:", JSON.stringify(responseUser));
      if (responseUser.data.status === "success") {
        this.user = JSON.parse(JSON.stringify(responseUser.data.body));

        this.bank_account_name =
          this.user.bank_account_name === null ||
          this.user.bank_account_name === "" ||
          this.user.bank_account_name === "null"
            ? "กรุณาเพิ่มข้อมูล"
            : this.user.bank_account_name;
        this.bank_account_no =
          this.user.bank_account_no === null ||
          this.user.bank_account_no === "" ||
          this.user.bank_account_no === "null"
            ? "กรุณาเพิ่มข้อมูล"
            : this.user.bank_account_no;
        this.bank_name =
          this.user.bank_name === null ||
          this.user.bank_name === "" ||
          this.user.bank_name === "null"
            ? "กรุณาเพิ่มข้อมูล"
            : this.user.bank_name;
        this.user_id_text = "IS" + this.user.user_id_text;

        //get getAmount
        const responseAmount = await axios.post("/v1/withdrawal/amount", {
          headers: {
                Cookie: "jwt=makeeee;"
            }
        });
        //console.log("responseAmount:"+JSON.stringify(responseAmount))
        if (responseAmount.data.status === "success") {
          this.wallet_amount = responseAmount.data.body[0].withdrawal_amount;
        } else {
          alert("พบข้อผิดพลาด ระหว่างดึงข้อมูลยอดเงินคงเหลือ");
        }
        this.is_loading = "N";
      } else {
        alert("พบข้อผิดพลาดกรุณา login อีกครั้ง");
        //this.$router.push({ path: "/auth/login" });
      }
    } catch (error) {
      alert("พบข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ", error);
      //this.$router.push({ path: "/auth/login" });
    }
  },
  methods: {
    async withdrawal() {

      if(this.bank_account_no === 'กรุณาเพิ่มข้อมูล'){
        alert('กรุณาเพิ่มข้อมูล เลขบัญชีธนาคาร');
        return false;
      }
      if(this.bank_name === 'กรุณาเพิ่มข้อมูล'){
        alert('กรุณาเพิ่มข้อมูล ธนาคาร')
        return false;
      }
      if(this.bank_account_name === 'กรุณาเพิ่มข้อมูล'){
        alert('กรุณาเพิ่มข้อมูล ชื่อบัญชี')
        return false;
      }


      if (this.wallet_amount <= 200) {
        alert("ยอดถอนเงินขั้นต่ำ 200 บาท");
        return false;
      } else {
        if (confirm("คุณต้องการถอนเงิน?")) {
          let data = {
            action: "creation",
            bank_name: this.bank_name,
            bank_account_no: this.bank_account_no,
            bank_account_name: this.bank_account_name,
            remark: "-",
          }
          console.log("data:", data);
          const res = await axios.post("/v1/withdrawal/creation",data, {
            withCredentials: true,
          });
          if (res.data.status === "success") {
            alert("คำขอเรียบร้อย");
            this.$router.push("/withdrawal-history");
          } else {
            alert("พบข้อผิดพลาด กรุณาทำรายการใหม่อีกครั้ง");
          }
        }
      }
    },
  },
};
</script>
