import { defineStore } from "pinia";

import { ref, computed } from 'vue'

import axios from 'axios'

export const useUserStore = defineStore('useUserStore', () => {
    const user = ref({});
    const jwt = ref('');
    

    const saveToLocalStorage = () => {
        localStorage.setItem('user', JSON.stringify(user.value))
        localStorage.setItem('jwt', jwt.value)
    }


    const loadFromLocalStorage = () => {
        if (localStorage.getItem('jwt')) {
            jwt.value = localStorage.getItem('jwt')
        }

        if (localStorage.getItem('user')) {
            user.value = JSON.parse(localStorage.getItem('user'))
        }
    }

    const clear = () => {
        user.value = {}
        jwt.value = ''
        saveToLocalStorage()
    }

    const checkUser = (user_param) => {
        console.log("user_param:"+user_param)
        let _user = JSON.parse(JSON.stringify(user.value));
        console.log("_user:::::"+_user.length)
        if(_user == null){
            console.log("nullllllllllllllllll")
            return false;
        }
        console.log("$$:"+JSON.stringify(user.value))
        //let _user = JSON.parse(JSON.stringify(user.value));
        console.log("$$$$:"+_user)
        if(_user.body.user_id){
            return true;
        }else{
            return false;
        }
    }

    return { user,jwt, saveToLocalStorage, loadFromLocalStorage,clear,checkUser }
});