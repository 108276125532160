<template>
  <div class="header-logo-wrapper col-4 p-0">
    <div class="logo-wrapper">
      <router-link to="/">
        <!-- <h3> ISHOOTRUN.IN.TH</h3>-->
        <img class="img-fluid" style="max-width: 140%;" src="../../assets/images/logo/logo.png" alt />
      </router-link>
    </div>
    <div class="toggle-sidebar" @click="toggle_sidebar">
      <vue-feather
        class="status_toggle middle sidebar-toggle"
        type="align-center"
        id="sidebar-toggle"
      ></vue-feather>
    </div>
    <router-link to="/">
      <img v-if="windowWidth <=990" class="img-fluid"  style="max-width: 100%;" src="../../assets/images/logo/logo.png" />
    </router-link>
  </div>

</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Logo",
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      sidebar: (state) => state.layout.sidebarType,
      activeoverlay: (state) => state.menu.activeoverlay,
      togglesidebar: (state) => state.menu.togglesidebar,
      width: (state) => state.menu.width,
      height: (state) => state.menu.height,
      margin: (state) => state.menu.margin,
      menuWidth: (state) => state.menu.menuWidth,
    }),
  },
  watch: {
    width() {
      this.windowWidth = window.innerWidth;
      //console.log("Logo.innerWidth watch :" + window.innerWidth);
    },
  },
  methods: {
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar");
    },
  },
};
</script>
