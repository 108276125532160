
<template>
  <Breadcrumbs main="Event" title="อัปโหลดสำเร็จ" />
  <div class="container-fluid">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-center">
            <!-- <img
              style="width: 40%; border-radius: 0.5rem;bo"
              :src="this.eventDetails.coverurl"
            /> -->
          </div>
          <p></p>
          <div class="d-flex justify-content-center">
            <h2>{{ this.eventDetails.title }}</h2>
          </div>

          <div class="d-flex justify-content-center">
            <h4>รูปที่ upload ทั้งหมด: {{ this.image_length }} รูป</h4>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header"><h6>รูปที่ Upload สำเร็จเรียงตามชื่อ</h6></div>
        <div class="row" v-if="this.is_loading === 'Y'">
          <div class="d-flex justify-content-center">
            <img
              style="align-items: center; width: 60px"
              src="../../assets/images/running_loading.svg"
            />
          </div>
        </div>
        <div class="my-gallery card-body row gallery-with-description">
          <div
            class="col-xl-4 col-sm-6 upload-item"
            v-for="(file, index) in this.image_uploaded"
            :key="index"
          >
            <b v-if="file.tag === 1" class="success"
              >{{ file.original_file_name }}[done]</b
            >
            <b v-else class="uploading"
              >{{ file.original_file_name }}[tagging]</b
            >
          </div>
        </div>
        &nbsp;
      </div>
    </div>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      eventDetails: {},
      image_uploaded: [],
      image_length: 0,
      is_loading: "",
    };
  },
  async created() {
    this.is_loading = "Y";
    let eventDetail = await this.getEventDetail(this.$route.params.q);

    await this.getUploadImages(eventDetail.event_id);

    this.is_loading = "N";
  },
  methods: {
    async getEventDetail(event_key) {
      const res = await axios.get("/v1/events/" + event_key);
      if (res.data.status === "success") {
        this.eventDetails = res.data;
        this.eventPackage = res.data.package;
        return res.data;
      } else {
        alert("[event]พบข้อผิดพลาด!");
        this.$router.push({ path: "/" });
      }
    },

    async getUploadImages(event_id) {
      const data = {
        event_id: event_id,
      };
      const response = await axios.post("v1/image/uploaded", data, {
        withCredentials: true,
      });

      if (response.data.status === "success") {
        this.image_uploaded = response.data.body;
        this.image_length = response.data.count;
      } else {
        alert("[upload-images]พบข้อผิดพลาด!");
        this.$router.push({ path: "/" });
      }
    },
  },
};
</script>
  <style scoped lang="scss">
#selectBtn {
  display: none;
}
.card-btn-upload {
  text-align: center;
}
.card-file-list {
  text-align: center;
}

.upload-item {
  border: 1px solid rgb(197, 197, 197);
  border-radius: 0.5rem;
  //padding: 0.5rem;
  //font-size: smaller;
  float: left;
  padding: 5px;
  text-align: center;
}
.drop-container:hover {
  background: #979696ad;
}
.upload-item:hover {
  background: #e0e0e0ad;
  cursor: pointer;
  border: 1px dashed rgba(124, 2, 2, 0.616);
}

.buttons {
  display: flex;
  gap: 1rem;

  &.centered {
    justify-content: center;
  }
}

.button {
  cursor: pointer;

  color: white;
  background: #809af0;
  text-decoration: none;

  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: 2px;

  &:hover {
    background: #3f6cff;
  }

  &.disabled {
    cursor: default;
    background: lightgray;
    pointer-events: none;
  }
}

.button-danger {
  background: #f08080;

  &:hover {
    background: #f65959;
  }
}

.button-upload {
  background: #00d591;

  &:hover {
    background: #02b97f;
  }
}

.failed {
  color: red;
}

.success {
  color: green;
}

.uploading {
  color: rgb(255, 102, 0);
}

.failed-border {
  border-color: red;
}
</style>