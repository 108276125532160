<template>
  <div class="col-xxl-4 col-sm-12 box-col-12">
    <div class="card profile-box">
      <div class="card-body">
        <div class="media">
          <div class="media-body">
            <div class="greeting-user">
              <h4 class="f-w-600 mb-0">สรุปคำสั่งซื้อ</h4>
              <p>{{ this.eventDetails.title }}</p>
              <!-- <p>{{ this.eventDetails.event_date }},{{ this.eventDetails.province }}</p> -->

              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
        <div class="cartoon">
          <img
            class="img-fluid"
            src="@/assets/images/dashboard/cartoon.svg"
            alt="vector women with leptop"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      eventDetails: {},
    };
  },
  mounted() {},

  async created() {
    const res = await axios.get("/v1/events/" + this.$route.params.q);
    //console.log("res.data", JSON.stringify(res.data));
    if (res.data.status === "success") {
      this.eventDetails = res.data;
      this.eventPackage = res.data.package;
      return res.data;
    } else {
      alert("[EventDetail]พบข้อผิดพลาด!");
      this.$router.push({ path: "/" });
    }
  },
};
</script>
