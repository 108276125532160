<template>
    <div class="row">&nbsp;</div>
<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="row">
                <WelcomeCard />
            </div>
        </div>
     

        <myEvent />
    </div>
</div>
</template>

<script>
import WelcomeCard from './WelcomeCard.vue';

import myEvent from './myEvent.vue';

export default {
    components: {
        WelcomeCard,
        myEvent
    }
};
</script>
