<template>
  <div class="row">&nbsp;</div>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="row">
          <WelcomeCard />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <h3>ISHOOTRUN.IN.TH </h3>
        <p>&nbsp;</p>
        <div class="d-flex justify-content-center">
          <h6>Line กลุ่มสำหรับช่างภาพ สามารถร่วม join พูดคุยสอบถามข้อมูลได้เลยครับ</h6>
        </div>
        <div class="d-flex justify-content-center">

          <a target="_blank" href="https://line.me/ti/g2/j8aEQ446fiZd5J3e1Qmti8-8VRN9Fs3rqBx8Sg?utm_source=invitation&utm_medium=link_copy&utm_campaign=default">
          <img
            width="200"
            src="@/assets/images/running/line-group.jpg"
            alt="line-group"
          />
        </a>
        </div>
              
        <br>
        <br>
        <br>
        <div>
          <b><i class="fa fa-info-circle"></i> คำแนะนำในการตั้งค่ากล้อง เพื่อให้ได้ขนาดไฟล์ที่ไม่ใหญ่มากและสามารถทำงานได้เร็วขึ้น</b>
              <p>ตั้งค่าคุณภาพของรูปที่ถ่ายเป็นแบบ JPEG (BASIC,LOW,LIGHT,เบา) เพื่อขนาดของรูปที่ไม่ใหญ่มากจนเกินไป และความเร็วในการ upload<br>
              <lu>  
                <li><a href="https://running.in.th/running-img/running-camera-setting-nikon.jpg" target="_blank">Nikon JPEG (BASIC)</a></li>
                <li><a href="https://support.usa.canon.com/kb/index?page=content&id=ART173768" target="_blank">Canon JPEG(LOW)</a></li>
                <li><a href="https://running.in.th/running-img/running-camera-setting-sony.jpg" target="_blank">Sony JPEG (LIGHT,เบา)</a></li>
              </lu>
            </p>
            <p>การตั้งค่า Lightroom เพื่อ Export <u><a href="https://running.in.th/running-img/running-export-lr-setting.jpg"  target="_blank">ตัวอย่างการตั้งค่า</a></u><br>
              <lu>  
                <li>Quality:70</li>
                <li>Resolution: 70</li>
                <li>Image size: 4300px (Long Edge)</li>
              </lu>
            </p>
        </div>

        <p>&nbsp;</p>
        <b>Logo ISHOOTRUN : <a href="https://drive.google.com/drive/u/0/folders/1wR3vUDVkSVljbfdF2BYM1BvgSbHrIpqL" target="_blank">ISHOOTRUN LOGO</a></b>&nbsp;<i class="fa fa-heart" style="font-size:15px;color:red"></i><br>
        <b>ติดต่อ สอบถามข้อมูลเพิ่มเติมหรือเปิดใช้งานระบบจำหน่ายภาพ : Line <a target="_blank" href="https://lin.ee/nMKDW7X">@ishootrun.in.th</a></b>
      
      </div>
    </div>
  </div>
</template>

<script>
import WelcomeCard from "./WelcomeCard.vue";

export default {
  components: {
    WelcomeCard,
  },
};
</script>
